





















import { computed, defineComponent } from '@vue/composition-api';
import { useCompanyStore } from '@/app/composables';

export default defineComponent({
  name: 'SearchCompany',
  setup() {
    const { query, isLoading, searchEnd, setQuery, setPaginateQuery, setPaginate, loadCompanies } =
      useCompanyStore();
    const companyQuery = computed({
      get(): string {
        return query.value;
      },
      set(val: string): void {
        setQuery(val);
      },
    });

    const handleSearch = (): void => {
      setPaginate(1);
      /*if (userQuery.value && userQuery.value !== '') {*/
      setPaginateQuery(companyQuery.value);
      loadCompanies().then();
      /* }*/
    };

    return { companyQuery, isLoading, searchEnd, handleSearch };
  },
});
