import { Space } from '@/app/models/space';

export interface CompanyModel {
  _id: string;
  name: string;
  address: string;
  ruc: string;
  created: string;
  updated: string;
  space: Space[];
}

export class CompanyFormValues {
  _id?: string = undefined;
  name = '';
  address: string | null = null;
  ruc: string | null = null;
  constructor(company?: CompanyFormValues) {
    Object.assign(this, company);
  }
}
