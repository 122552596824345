





















import { defineComponent, inject } from '@vue/composition-api';
import SearchCompany from '@/features/companies/_components/search-company/SearchCompany.vue';
import { CompanyModel } from '@/app/models/company';

export default defineComponent({
  name: 'CompaniesHeader',
  components: { SearchCompany },
  setup() {
    const openModalCompanyForm =
      inject<(company: CompanyModel | null) => void>('openModalCompanyForm');
    return { openModalCompanyForm };
  },
});
