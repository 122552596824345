

























import { defineComponent, onMounted } from '@vue/composition-api';
import CustomMainTable from '@/app/common/components/custom-main-table/CustomMainTable.vue';
import CompaniesTable from '@/features/companies/companies-table/CompaniesTable.vue';
import CustomProgressCircular from '@/app/common/components/custom-progress-circular/CustomProgressCircular.vue';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import { useCompanyStore } from '@/app/composables';

export default defineComponent({
  name: 'CompaniesList',
  components: { CustomMessage, CustomProgressCircular, CompaniesTable, CustomMainTable },
  setup() {
    const { isLoading, error, loadCompanies, searchEnd } = useCompanyStore();
    onMounted(() => {
      loadCompanies();
    });
    return {
      isLoading,
      error,
      searchEnd,
    };
  },
});
