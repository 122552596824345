import { defineComponent, reactive, ref } from '@vue/composition-api';
import { RefFormModel } from '@/app/models/reference';
import { CompanyFormValues, CompanyModel } from '@/app/models/company';
import { required, numbersValidator, lengthValidator } from '@/app/common/validator';
import { validNumbers } from '@/app/common/utils/check-validators';
import { useCompanyStore } from '@/app/composables';

export default defineComponent({
  name: 'ModalCompanyForm',
  setup() {
    const { addCompany, editCompany, loadCompanies, setPaginate } = useCompanyStore();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const editMode = ref<boolean>(false);
    const companyForm = ref<RefFormModel | null>(null);

    const form = reactive<CompanyFormValues>(new CompanyFormValues());

    const openDialog = (company: CompanyModel | null) => {
      if (company) {
        editMode.value = true;
        const userForm: CompanyFormValues = {
          ...form,
          ...company,
        };
        Object.assign(form, new CompanyFormValues(userForm));
      }
      isDialogVisible.value = true;
    };

    const closeDialog = () => {
      if (!companyForm.value) return;
      isDialogVisible.value = false;
      Object.assign(form, new CompanyFormValues());
      companyForm.value.resetValidation();
      editMode.value = false;
    };

    const handleFormSubmit = () => {
      const isFormValid = companyForm.value!.validate();
      if (!isFormValid) return;
      isLoading.value = true;
      if (editMode.value) {
        editCompany(form)
          .then(() => {
            /* restoreUsersPagingParams(itemsForPage.value);*/
            loadCompanies().then();
            closeDialog();
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
      if (!editMode.value) {
        addCompany(form)
          .then(() => {
            /*restoreUsersPagingParams(itemsForPage.value);*/
            setPaginate(1);
            loadCompanies().then();
            closeDialog();
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    return {
      isDialogVisible,
      isLoading,
      valid,
      editMode,
      form,
      handleFormSubmit,
      openDialog,
      closeDialog,
      validators: {
        required,
        numbersValidator,
        lengthValidator,
      },
      checkValidators: {
        validNumbers,
      },
      companyForm,
    };
  },
});
