


































































import { defineComponent, inject } from '@vue/composition-api';
import { useCompanyStore } from '@/app/composables';
import { formatDateLocal, formatScript } from '@/app/common/utils/filter';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import { CompanyModel } from '@/app/models/company';

export default defineComponent({
  name: 'CompaniesTable',
  components: { CustomMessage },
  setup() {
    const { companies, searchMode, isLoading, searchEnd } = useCompanyStore();
    const openModalCompanyForm =
      inject<(company: CompanyModel | null) => void>('openModalCompanyForm');

    return {
      companies,
      searchMode,
      isLoading,

      searchEnd,
      openModalCompanyForm,

      filters: { formatDateLocal, formatScript },
    };
  },
});
