









import { defineComponent, onUnmounted, provide, ref } from '@vue/composition-api';
import CompaniesHeader from '@/features/companies/companies-header/CompaniesHeader.vue';
import CompaniesList from '@/features/companies/companies-list/CompaniesList.vue';
import CompaniesPaginate from '@/features/companies/companies-paginate/CompaniesPaginate.vue';
import { useCompanyStore } from '@/app/composables';
import ModalCompanyForm from '@/features/companies/_components/modals/modal-company-form/ModalCompanyForm.vue';
import { CompanyFormModalRef } from '@/app/models/modal';
import { CompanyModel } from '@/app/models/company';

export default defineComponent({
  name: 'CompaniesView',
  components: { ModalCompanyForm, CompaniesPaginate, CompaniesList, CompaniesHeader },
  setup() {
    const { resetStatus } = useCompanyStore();
    const modalCompanyForm = ref<CompanyFormModalRef | null>(null);

    onUnmounted(() => {
      resetStatus();
    });

    const openModalCompanyForm = (company: CompanyModel | null) => {
      if (!modalCompanyForm.value) return;
      modalCompanyForm.value.openDialog(company);
    };

    provide<(company: CompanyModel | null) => void>('openModalCompanyForm', openModalCompanyForm);

    return { modalCompanyForm, openModalCompanyForm };
  },
});
